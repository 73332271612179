/* Custom HMK Checkbox implementation */
form input[type="checkbox"] {
  visibility: hidden;
}
.hmk_checkbox label {
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0.375rem;
  left: 0.75rem;
  background: #eee;
  border: 1px solid #ddd;
}
.hmk_checkbox label:after {
  opacity: 0.2;
  content: "";
  position: absolute;
  width: 15px;
  height: 8px;
  background: transparent;
  top: 5px;
  left: 5px;
  border: 3px solid #333;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}
.hmk_checkbox label:hover::after {
  opacity: 0.5;
}
.hmk_checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
