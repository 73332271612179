.controller-container-wrapper,
.controller-action-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #222222;
  border: 2px solid #333333;
  border-radius: 0.5rem;
  padding: 5px;
  animation: 1s fade-in;
}
.controller-container-wrapper {
  margin-bottom: 10px;
}
.controller-action-wrapper {
  max-height: calc(100% - 300px);
  overflow: hidden;
}
.controller-container {
  width: 100%;
  border-radius: 0.5rem;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.controller-action-container {
  width: 100%;
  max-height: 100%;
  overflow-y: overlay;
  text-align: center;
  color: #ffffff;
}
.controller-action-container::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0) !important;
}
.controller-icon {
  width: 75%;
  height: 75%;
}
.controller-icon-interactive {
  color: #ffffff;
  cursor: pointer;
}
.controller-icon-interactive:hover {
  color: orange;
}
.controller-icon-filter {
  filter: invert(98%) sepia(100%) saturate(6%) hue-rotate(121deg)
    brightness(103%) contrast(101%);
}
.controller-icon-filter:hover {
  filter: invert(75%) sepia(22%) saturate(6067%) hue-rotate(357deg)
    brightness(98%) contrast(108%);
}
.action-container-title {
  margin-left: 5px;
  align-self: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.label_position_selector_container {
  width: 100px;
  height: 100px;
  background-color: #444444;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  place-items: center;
  z-index: 10;
}
.controller-statusbar {
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 0.5rem;
  align-items: center;
  background-color: #434343;
}
.controller-color-container {
  height: 50px;
  padding: 5px;
  justify-content: space-around !important;
}
.controller-color-container-left {
  flex: 1;
  padding-right: 5px;
  padding-left: 5px;
  height: 100%;
  display: grid;
  place-items: center;
}
.controller-color-container-right {
  flex: 1;
  margin-left: 10px;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: #000;
  border-radius: 0.5rem;
}
.controller-label-size-container,
.controller-stroke-width-container {
  margin-top: 5px;
  height: 35px;
  justify-content: space-between !important;
  align-content: center;
}
.controller-label-size-container input[type="number"],
.controller-stroke-width-container input[type="number"] {
  width: calc(100% - 160px);
}
.controller-color-container input[type="range"] {
  accent-color: orange;
}
.controller-label-position-container {
  margin-top: 5px;
  height: 100px;
}
.geometry-filter-container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.controller-search-bar-container {
  position: relative;
  height: 35px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.controller-search-bar-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  pointer-events: none;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
.controller-checkbox-wrapper {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 0.5rem;
  display: grid;
  place-items: center;
}
.controller-search-bar {
  width: 100%;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
