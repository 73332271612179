.picture-viewer-wrapper {
  text-align: center;
  top: 62px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  position: absolute;
}
.pv-relative-correction {
  width: 100%;
  height: 100%;
  position: relative;
}
.picture-view-container {
  position: absolute;
  top: 50%;
  left: 50%;
}
.picture-view-img {
  position: relative;
  max-width: 100vw;
  max-height: 92vh;
  border: #aaa 2px solid;
  border-radius: 5px;
}
.picture-view-canvas {
  mix-blend-mode: multiply; /*normal;*/
  position: absolute;
  left: 0;
  top: 0px;
  max-width: 100vw;
  max-height: 92vh;
  z-index: 2;
  border-width: 2px;
  border-style: solid;
  border-color: #0000;
  border-radius: 5px;
}
.picture-view-svg {
  position: absolute;
  left: 0;
  top: 0px;
  max-width: 100vw;
  max-height: 92vh;
}
.picture-view-interact-svg {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
}
/*
.picture-view-interact-svg {
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  height: calc(100% - 100px);
}
*/
.picture-view-left {
  position: fixed;
  top: 50%;
  left: 5%;
  z-index: 2;
}
.picture-view-left-button {
  margin-top: 20px;
}
.picture-view-left-button:hover {
  color: orange;
  cursor: pointer;
}

.picture-view-right {
  position: fixed;
  top: 50%;
  right: 5%;
  z-index: 2;
}
.picture-view-right-button {
  margin-top: 20px;
}
.picture-view-right-button:hover {
  color: orange;
  cursor: pointer;
}

.picture-view-footer {
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;
}

.picture-view-widget-container {
  position: absolute;
  transform: translateX(50%);
  top: 64px;
  display: flex;
  flex-direction: column;
  background-color: #222222;
  padding: 10px;
  border-radius: 0.5rem;
  border: 1px solid #333333;
  z-index: 100;
}

.picture-view-colorpicker {
  position: absolute;
  transform: translateX(-3px);
  top: 70px;
  z-index: 2;
}

.picture-view-widget-container input[type="range"] {
  margin-bottom: 10px;
  accent-color: orange;
}

.picture-view-widget-button {
  display: inline-block;
  font-weight: 400;
  vertical-align: middle;
  user-select: none;
  line-height: 1.5;
  font-size: 0.8rem;
  border-radius: 5px;
  border: 1px solid;
  border-color: white;
  background-color: rgba(50, 50, 50, 0.2);
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.picture-view-imgedit-button {
  width: 47.5%;
}
.picture-view-processing-button {
  width: 100%;
}
.picture-view-resizing-tool-button {
  width: 140px;
}
.picture-view-resizing-tool-button:not(:last-child) {
  margin-bottom: 5px;
}

.picture-view-widget-button:hover {
  border-color: orange;
  color: orange;
}

/*.picture-view-imgedit-button:not(:last-child) {
  margin-bottom: 10px;
}*/

.loading-spinner {
  width: 125px;
  height: 125px;
  position: absolute;
  top: 50%;
  left: 50%;
  border: solid 12px orange;
  border-radius: 50%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: spinner 1s infinite;
  transition-duration: 250ms;
}

@keyframes spinner {
  0% {
    transform: translate(-62px, -62px) rotate(0deg);
  }
  50% {
    transform: translate(-62px, -62px) rotate(180deg) scale(1.2);
  }
  100% {
    transform: translate(-62px, -62px) rotate(360deg);
  }
}
