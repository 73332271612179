.viewer3d {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  /*margin-top: 90px;*/
}
.viewer3d canvas {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
}

.viewer3d-footer {
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%);
}
