.action-bar-wrapper {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action-bar-icon-wrapper,
.label-position-button,
.action-bar-icon {
  width: 30px;
  height: 30px;
}
.action-bar-icon-wrapper {
  cursor: pointer;
}
.label-position-center {
  width: 100%;
  height: 100%;
}
.label-position-button-wrapper {
  display: grid;
  place-items: center;
}
.label-position-button {
  border-radius: 33%;
  display: grid;
  place-items: center;
}
