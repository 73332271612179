/* Forms  */

form input,
form textarea,
form select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18pt;
  line-height: 1.75em;
  font-weight: 100;
  letter-spacing: 1px;
  text-shadow: 0 0 0.5px rgba(58, 57, 57, 0.25);
  -webkit-text-stroke: 0.25px;
}

form select {
  cursor: pointer;
  background: url("../imgsrc/Hamburger_icon.svg") #333 no-repeat 98.5% !important;
}

form label {
  display: block;
  margin: 0 0 0.5em 0;
}

form input[type="text"],
form input[type="number"],
form input[type="email"],
form input[type="password"],
form select.form-control,
form textarea,
.form-control[readonly] {
  -moz-transition: background-color 0.35s ease-in-out;
  -webkit-transition: background-color 0.35s ease-in-out;
  -ms-transition: background-color 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out;
  -webkit-appearance: none;
  display: block;
  border: 0;
  background: #333;
  width: 100%;
  /*padding: 0.75em;*/
  border-radius: 0;
  color: white;
}

form input[type="text"]:focus,
form input[type="number"]:focus,
form input[type="email"]:focus,
form input[type="password"]:focus,
form input[type="checkbox"]:focus,
form select:focus.form-control,
form textarea:focus {
  background: #333;
  color: white;
  outline: none;
}
form input[type="text"]:disabled,
form input[type="number"]:disabled,
form input[type="email"]:disabled,
form input[type="password"]:disabled,
form input[type="checkbox"]:disabled,
form select:disabled.form-control,
form textarea:disabled {
  background: #333;
  color: #666;
  outline: none;
}

form input[type="text"],
form input[type="email"],
form input[type="password"],
form select {
  line-height: 1em;
}

form textarea {
  min-height: 12em;
}

form ul.actions {
  margin-bottom: 0;
  text-align: center;
}

.form-control:focus {
  border-color: rgb(77, 144, 254);
  box-shadow: 0 0 0 0.15rem rgba(0, 123, 255, 0.25);
}

.form-group input {
  border: 1px solid #f44336;
}

.modal-text {
  text-align: center;
}

.fixed-background,
.fixed-background:focus {
  background-color: #333;
  color: white;
}

.paste_option_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
}
.paste_render_container {
  margin-top: 15px;
  max-height: 400px;
  width: 100%;
  display: grid;
  place-items: center;
}
.paste_source_img {
  max-height: 400px;
  border: solid;
  border-width: 2px;
  border-color: #ffa500;
}
.paste_source_geom {
  position: absolute;
  opacity: 0.5;
  max-height: 400px;
}
.paste_interactive_geom_container {
  position: absolute;
  z-index: 20;
}
.paste_ghost_geom_container {
  position: absolute;
  top: 0;
  opacity: 0;
  pointer-events: none;
}

.paste_option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding: 5px;
  border-radius: 0.5rem;
  margin-left: 10px;
  margin-right: 10px;
}
.paste_option_inactive {
  background-color: #292323;
}
.paste_option_active {
  background-color: #333333;
}
.paste_option:hover {
  background-color: #444444;
}
.paste_option_adjustment {
  width: 1.5rem;
}
.paste_option_adjustment_available:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 25%;
}
.paste_option_adjustment_left {
  color: #eb3941;
}
.paste_option_adjustment_left:hover {
  color: #ff4a52;
}
.paste_option_adjustment_right {
  color: #60eb38;
}
.paste_option_adjustment_right:hover {
  color: #92ff73;
}

.json-editor-message-container {
  width: 100%;
  display: grid;
  place-items: center;
  margin-bottom: 25px;
}
.json-editor-undo {
  position: absolute;
  color: #ffffff;
  right: 50px;
  bottom: 100px;
  cursor: pointer;
  display: grid;
  place-items: center;
}
.json-wrapper {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  white-space: pre;
  border-radius: 0.5rem;
  overflow-x: hidden;
  /*padding-right: 5px;*/
  min-height: min(400px, 40vh);
  margin-bottom: 20px;
  font-size: 1rem;
  background-color: #111111;
}
.json-wrapper::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0) !important;
}
.button-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
