.controller-button-enabled,
.controller-button-disabled {
  display: grid;
  place-items: center;
  border-radius: 0.5rem;
}
.controller-button-enabled {
  background-color: #434343;
  cursor: pointer;
  pointer-events: auto;
}
.controller-button-enabled:hover {
  color: orange;
}
.controller-button-disabled {
  background-color: #191919;
  color: #666666;
  cursor: default;
}
.controller-override-color-orange {
  color: #ffa500;
}
.controller-override-color-white {
  color: #ffffff;
}
