.controller-object-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.controller-object-row:not(:last-child) {
  margin-bottom: 4px;
}

.controller-object-category,
.controller-object-category-interactive,
.controller-object-confirm {
  display: grid;
  place-items: center;
  background-color: #434343;
  border-radius: 0.5rem;
  width: 35px;
  height: 35px;
}

.controller-object-category-interactive {
  cursor: pointer;
  color: #ffffff;
}

.controller-object-category,
.controller-object-category-interactive {
  margin-right: 5px;
}

.controller-object-category-interactive:hover {
  color: orange;
}

.controller-object-confirm {
  animation-duration: 500ms;
  margin-left: 5px;
  cursor: pointer;
}
.controller-object-confirm:hover {
  color: orange;
  animation: 0.25s color-fade-in-orange;
}

/* color input */
.controller-container input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #b5b5b5;
  border-radius: 0.5rem;
  border: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}
.controller-container input[type="color"]:hover {
  background: orange;
}
.controller-container input[type="color"]:focus {
  background: orange;
}
.controller-container input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 3px;
}

.controller-container input[type="color"]::-webkit-color-swatch {
  border: 0;
  border-radius: 0.33rem;
}

.controller-container input[type="color"]::-moz-color-swatch,
.controller-container input[type="color"]::-moz-focus-inner {
  border: 0;
}

.controller-container input[type="color"]::-moz-focus-inner {
  padding: 0;
}

/* opacity slider */
.controller-container input[type="range"] {
  border-radius: 5px;
  border: 0;
  width: 100%;
  height: 10px;
  margin: 0px;
  padding: 0px;
}

/* un-checked checkbox */
.controller-object-row input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  content: "";
  border: 1px solid grey;
  display: inline-block;
  vertical-align: -2px;
  border-radius: 0.5rem;
  animation: 0.5s bg-fade-out-orange-34;
  background-color: #222222;
  /** size tied to its parent's wrapper style. */
  width: 100%;
  height: 100%;
}
.controller-object-row input[type="checkbox"]:hover:not(:disabled) {
  animation: 0.5s bg-fade-out-orange-72;
  background-color: rgba(72, 72, 72, 1);
}

.controller-object-row:not(#controller-master) input[type="checkbox"]:checked {
  background-image: url("../../imgsrc/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: orange;
  border: 2px solid #ffffff;
  color: white;
  animation: 0.33s bg-fade-in-orange;
}

#controller-master input[type="checkbox"] {
  background-image: url("../../imgsrc/check_all.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px;
}

#controller-master input[type="checkbox"]:checked {
  background-image: url("../../imgsrc/check_all_black.svg");
  background-color: orange;
  border: 2px solid #ffffff;
  color: white;
  animation: 0.33s bg-fade-in-orange;
}

.controller-object-row input[type="checkbox"]:not(:disabled) {
  outline-color: transparent;
  outline-width: 2px;
  outline-style: dotted;
  cursor: pointer;
}

/* Disabled checkbox styles */
#controller-master input[type="checkbox"]:disabled,
.controller-object-row input[type="checkbox"]:disabled {
  cursor: default;
  color: black;
  opacity: 0.5;
}

.controller-textinput {
  background-color: rgba(25, 25, 25, 1);
  border: none;
  outline: none;
  height: 35px;
  border-radius: 0.5rem;
  color: #fff;
  font-size: 1.25rem;
  padding-left: 5px;
  padding-right: 5px;
  transition-duration: 500ms;
}

/* text selection color */
.controller-textinput::selection {
  background: rgba(255, 255, 255, 0.25);
}
/* text selection color (Firefox) */
.controller-textinput::-moz-selection {
  background: rgba(255, 255, 255, 0.25);
}
.controller-textinput:hover {
  background-color: rgba(50, 50, 50, 1);
}
.controller-textinput:focus {
  border: none;
  border: 2px solid #ffffff;
  background-color: rgba(10, 10, 10, 1);
}

@keyframes color-fade-in-orange {
  0% {
    color: rgba(255, 255, 255, 1);
  }
  100% {
    color: rgba(255, 165, 0, 1);
  }
}
@keyframes color-fade-out-orange {
  0% {
    color: rgba(255, 165, 0, 1);
  }
  100% {
    color: rgba(255, 255, 255, 1);
  }
}
@keyframes bg-fade-in-orange {
  0% {
    background-color: rgba(72, 72, 72, 1);
  }
  100% {
    background-color: rgba(255, 165, 0, 1);
  }
}
@keyframes bg-fade-out-orange-34 {
  0% {
    background-color: rgba(255, 165, 0, 0.75);
  }
  100% {
    background-color: rgba(34, 34, 34, 1);
  }
}
@keyframes bg-fade-out-orange-72 {
  0% {
    background-color: rgba(255, 165, 0, 0.75);
  }
  100% {
    background-color: rgba(72, 72, 72, 1);
  }
}
