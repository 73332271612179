/* Main */

html {
  position: relative;
  min-height: 100%;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
img,
svg {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  text-shadow: 0 0 0.5px rgba(58, 57, 57, 0.25);
  color: white;
  /*margin-top: 8rem;*/
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  /*text-transform: uppercase;*/
  line-height: 1.75em;
}

h2 {
  font-size: 1.75em;
  letter-spacing: 8px;
}

.jumbotron {
  position: relative;
}

::-webkit-scrollbar {
  background-color: #222;
  width: 15px;
}
::-webkit-scrollbar-track {
  border-radius: 15px;
}
::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 15px;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-11-1 0:7:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 HoloMake animated loader
 */
.loader {
  position: fixed;
  text-align: left;
  bottom: 10px;
  left: 10px;
  color: #ff9900;
}

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rotate-center {
  animation: rotate-center 0.8s 1s infinite alternate both;
}

.dark-toast {
  background-color: gray;
  color: white;
}
