.pv-wrapper-color {
  position: fixed;
  text-align: left;
  top: 70px;
  right: 50px;
  background-color: black;
  z-index: 2;
}

.wrapper-color {
  /*display: block;*/
  position: absolute;
  z-index: 10;
  top: 40px;
  /*left: 100px;*/
}
