.modal {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto; /* Enable scroll if needed */
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

/*
* Dynamic modal size - the JSON editor's textarea size is unpredictable.
*/
.modal-main {
  position: absolute;
  min-width: min(600px, 55vw);
  margin: 100px auto;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid orange;
  background: rgba(30, 30, 30, 1);
  left: 50%;
  top: 10%;
  transform: translateX(-50%) translateY(-10%);
}
@media screen and (max-width: 900px) {
  .modal-main {
    width: 90%;
  }
}

.modal-main .title {
  overflow-wrap: break-word;
}

.modal-main form {
  padding-top: 30px;
}

.modal-main button {
  font-size: 1rem;
  margin-top: 0px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid white;
  background-color: rgba(50, 50, 50, 0.2);
  color: white;
}
.modal-main button:active,
.modal-main button:focus {
  color: white;
  background-color: rgba(50, 50, 50, 0.2);
}
.modal-main button:hover {
  color: orange;
  border-color: orange;
}

/* This is specific to the CAD Render dialog button group */
.modal-main .btn-group-toggle label {
  font-size: 1rem;
  margin-top: 0px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid white;
  background-color: rgba(50, 50, 50, 0.2);
  color: white;
}
.modal-main .btn-group-toggle label.active {
  color: orange;
  border-color: orange;
}
.modal-main .btn-group-toggle label:hover {
  border: 2px solid orange;
}
.modal-main .btn-group-toggle label.focus {
  box-shadow: none;
}

/*
.modal-main input[type="text"] {
  border: none;
  font-size: 1rem;
  background-color: rgba(50, 50, 50, 0.2);
  color: white;
  width: 100%;
  box-sizing: border-box;
}
.modal-main input[type="text"]:focus {
  outline-color: #555;
  border: 1px solid #555;
  background-color: rgba(80, 80, 80, 0.2);
}
.modal-main input[type="text"]:hover {
  background-color: rgba(80, 80, 80, 0.2);
}
*/

.hmk-btn-close {
  font-size: 1.5em;
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
}
.hmk-btn-close:hover {
  color: orange;
}
