* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Simple Card styles for prettying */
.card-container {
  padding: 0rem 0rem 0rem 1rem;
  display: grid;
  grid-gap: 2.2rem 4.1rem;
  grid-template-columns: repeat(auto-fit, 310px);
  margin-top: 90px;
}

.card {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 2px solid #888;
  background-color: black;
  /*transition: 0.3s;
  height: auto;*/
  width: 340px;
  height: 340px;
  outline: none;
}
.card:hover {
  border-color: orange;
  /*border: 10px solid #fff;
  box-shadow: 0px 3px 6px #fff;*/
  background-color: #111;
  cursor: pointer;
}
.card.card-shadow {
  border-radius: 5px;
  border: 1px dashed #888;
}
.card.card-shadow:hover {
  border-color: orange;
}
.card-shadow label {
  cursor: pointer;
}
/*
.card:hover {
    outline: none;
    border: 10px solid #fff;
    box-shadow: 0px 3px 6px #fff;
}
.card-content:hover {
  background-color: #101010;
  cursor: pointer;
}
*/

.card-action-dropdown {
  padding: 0px 0px 0px 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
}
.card-action-dropdown li:hover {
  color: orange;
  cursor: pointer;
}

.card-action-cont {
  /*position: relative;
  display: flex;
  float: right;*/
  position: absolute;
  transform: translate(0px, -8px);
  display: block;
  margin-right: 0rem;
  text-decoration: none;
  color: white;
}

.card-action {
  display: inline-flex;
  outline: none;
  color: white;
  background-color: transparent;
  border: none;
  margin-top: 15px;
  width: 1.5rem;
  cursor: pointer;
}
.card-action:hover {
  color: orange;
}
.card-file-action {
  outline: none;
  color: white;
  background-color: black;
  border: none;
  margin-top: 15px;
  width: 80px;
}
.card-file-action:hover {
  color: orange;
}

a {
  text-decoration: none;
}

.card__title {
  font-size: 1.5rem;
  color: white;
  padding: 0.5rem;
  text-align: center;
}

/** json command available */
.card__subtitle {
  position: absolute;
  left: 50%;
  bottom: 25px;
  transform: translateX(-50%);
}

.card__description {
  color: white;
  padding: 0.5rem;
  line-height: 1.6em;
  text-align: center;
}

.card__thumbnail {
  /*position: relative;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);*/
  padding: 0.5rem;
  text-align: center;
  font-size: 0.8rem;
}

.card__thumbnail-caption-reso {
  position: absolute;
  background-color: #00000080;
  bottom: 12px;
  right: 12px;
  font-size: 0.8rem;
}
.card__thumbnail-caption-name {
  position: absolute;
  background-color: #00000080;
  bottom: 12px;
  left: 12px;
  font-size: 0.8rem;
}
.card__thumbnail-caption-picto {
  position: absolute;
  top: 15px;
  left: 20px;
  opacity: 0.8;
}

.card__thumbnail a {
  color: white;
}
.card__thumbnail p {
  padding-top: 10px;
}

.card__thumbnail-dir {
  position: relative;
}
.card__thumbnail-img {
  max-height: 320px;
  max-width: 320px;
  object-fit: contain;
  border: #aaa 1px solid;
  border-radius: 3px;
}
.card__thumbnail-compo {
  object-fit: contain;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-height: 320px;
  max-width: 320px;
}
.card__thumbnail-dir-img {
  width: 80%;
  height: auto;
  max-height: 300px;
  padding: 30px;
  object-fit: contain;
}
.card__thumbnail-file {
  width: auto;
  height: auto;
  max-width: 320px;
  max-height: 300px;
  object-fit: contain;
}
.card__thumbnail path {
  stroke: white;
}

.card__progress {
  position: absolute;
  width: 80%;
  /*top: 50%;*/
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.card__progress_bar-symbol {
  color: white;
}

.custom_loader {
  /*display: block;
  position: relative;
  left: 50%;
  top: 50%;*/
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #9370db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.custom_loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #ba55d3;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.custom_loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ff00ff;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
.GLTF-tag {
  position: absolute;
  bottom: 18px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
