* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.partview_wrapper {
  padding-top: 62px;
  display: flex;
  flex: 1;
  min-height: calc(100vh - 62px);
}
.render_container {
  display: grid;
  place-items: center;
  flex: 1;
  padding: 20px;
}
.controller_container {
  position: -webkit-sticky;
  position: sticky;
  right: 20px;
  top: 62px;
  width: 350px;
  padding: 20px 20px 0px 20px;
  height: calc(100vh - 82px);
  transition-duration: 500ms;
  z-index: 100;
}
.controller_container_disabled {
  opacity: 0.5;
  pointer-events: none;
}
.controller_container_invisible {
  opacity: 0;
  pointer-events: none;
}

.partview-left {
  position: fixed;
  top: 50%;
  left: 5%;
  z-index: 200;
}
.partview-left-button {
  margin-top: 20px;
}
.partview-left-button:hover {
  color: orange;
  cursor: pointer;
}

.partview-right {
  position: fixed;
  top: 50%;
  right: 5%;
  z-index: 200;
}
.partview-right-button {
  margin-top: 20px;
}
.partview-right-button:hover {
  color: orange;
  cursor: pointer;
}


.holo_svg:hover .holo_element,
.holo_element,
.holo_selected {
  color: red;
}

.info-bar {
  position: fixed;
  text-align: right;
  bottom: 10px;
  right: 10px;
}

.nameform {
  display: inline;
  position: fixed;
  text-align: right;
  max-width: 450px;
  top: 125px;
  left: 10px;
  font-size: 15px;
  height: 85vh;
  direction: rtl;
  padding-left: 0px;
  padding-right: 32px;
  overflow: auto;
  z-index: 5;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.nameform input[type="text"] {
  padding: 8px 10px;
  margin: 0px 0px 0px 5px;
  border: none;
  font-size: 15px;
  background-color: rgba(50, 50, 50, 0.6);
  border-radius: 0.5rem;
  color: white;
  height: 35px;
}
.nameform input[type="text"]:hover {
  background-color: rgba(50, 50, 50, 0.8);
}
.nameform input[type="text"]:focus {
  border: 1px solid #555;
  background-color: rgba(10, 10, 10, 0.9);
}
.nameform input[type="checkbox"] {
  padding: 8px 10px;
  margin: 0px 10px;
  border: none;
  font-size: 15px;
  background-color: rgba(50, 50, 50, 0.6);
  color: white;
}

.nameform-field {
  /*display: block;*/
  display: flex;
  position: relative;
  flex-direction: row;
  margin-bottom: 5px;
  padding-left: 10px;
  direction: ltr;
}
.namelabelform {
  display: inline-block;
  width: 80px;
}

.nameform-close,
.nameform-color {
  max-width: 35px;
  max-height: 35px;
  color: #ccc;
  font-size: 20px;
  background-color: rgba(50, 50, 50, 0.6);
  border-radius: 0.5rem;
  border: none;
  display: grid;
  place-items: center;
}
.nameform-close:hover,
.nameform-color:hover {
  color: #fff;
  background-color: rgba(50, 50, 50, 0.8);
}
.nameform-input {
  border-radius: 25%;
}

.card__view {
  position: relative;
}
.svg_canvas {
  position: absolute;
  display: inline-block;
  top: 0px;
  z-index: 3;
}
.holo_svg_canvas {
  display: inline-block;
  top: 0px;
  z-index: 3;
}
.card__view-img {
  /* force display inline-block cause sometimes img can be a div when display a svg content */
  display: inline-block;
  z-index: 1;
  padding: 0px;
}
