/* Navigation  */
.navbar {
  border-width: 2px;
  border-style: solid;
  border-color: #333;
  background-color: #222;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  /*padding: 1rem;*/
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: orange;
}
.navbar-dark .navbar-nav .nav-link.selected {
  color: orange;
}

.navbar-dark .navbar-toggler {
  outline: none;
}

.navbar-text {
  color: white;
}

.navbar-brand {
  color: white;
}

.navbar-brand button {
  outline: none;
  color: white;
  background-color: transparent;
  border: none;
}
.navbar-brand svg {
  font-size: 1.2em;
  margin-left: 10px;
}
.navbar-brand svg:hover {
  color: orange;
  cursor: pointer;
}
.navbar-brand-title {
  margin-left: 20px;
}

.navbar-brand img {
  margin-right: 0.5rem;
  cursor: pointer;
}

ul li {
  list-style-type: none;
}

.navbar-btn-action {
  margin-left: 5px;
  color: white;
  font-size: 1.4em;
  position: relative;
  cursor: pointer;
}

.navbar-btn-action:hover {
  color: orange;
}
.navbar-btn-action.selected {
  color: orange;
  transform: scale(1.5);
}
.navbar-btn-action > * {
  outline: none;
}

.nav-text-path {
  display: flex;
  align-items: center;
}
.nav-text-path span {
  white-space: nowrap;
  padding: 0px 5px;
  /*font-size: 0.7em;*/
}

.navbar-menu-custom {
  display: inline-flex;
}

.nav-link {
  padding: 0;
}
.nav-link label {
  margin-bottom: 0;
  cursor: pointer;
}

.nav-link-path path {
  stroke: white;
}

.nav-link-path:hover path {
  stroke: orange;
}

.root-navigation:hover {
  color: orange;
  cursor: pointer;
}

.hiddenIcon {
  display: none;
}
